<template>
  <div>
    <component
      :is="modals[id]"
      v-for="id in stack"
      :key="id"
      :props-modal="id"
    />
  </div>
</template>

<script setup lang="ts">
import { modal } from '~/src/composables/core/modals'

const { stack, modals } = modal
</script>
